import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Jai from "../images/jai.jpg"

const imgStyle = {
  width: "30%",
}

const bioStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}

const AboutPage = () => (
  <Layout>
    <SEO title="About Me" />
    <h1>About Me</h1>
    <div style={bioStyle}>
      <img src={Jai} style={imgStyle} />
      <p>My name is Jai Miles.</p>
    </div>
  </Layout>
)

export default AboutPage
